import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-screening-link',
	templateUrl: './screening-link.component.html',
	styleUrls: ['./screening-link.component.scss'],
})
export class ScreeningLinkComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
