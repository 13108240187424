import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NbPasswordAuthStrategy, NbAuthModule, NbAuthJWTToken, NbAuthJWTInterceptor } from '@nebular/auth';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './pages/auth/token.interceptor';
import { ToastrModule } from 'ng6-toastr-notifications';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardHeaderElementsComponent } from './card-header-elements/card-header-elements.component';
import { NbDatepickerModule } from '@nebular/theme';


@NgModule({
  declarations: [AppComponent, CardHeaderElementsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            class: NbAuthJWTToken
          },
          baseEndpoint: environment.apiUrl,
          login: {
            endpoint: '/auth/sign-in',
            method: 'post',
            redirect: {
              success: '/pages/dashboard',
              failure: null
            }
          },
          register: {
            endpoint: '/auth/sign-up',
            method: 'post'
          },
          logout: {
            endpoint: '/auth/sign-out',
            method: 'delete'
          },
          requestPass: {
            endpoint: '/auth/request-pass',
            method: 'post'
          },
          resetPass: {
            endpoint: '/auth/reset-pass',
            method: 'post'
          }
        })
      ],
      forms: {
        validation: {
          password: {
            minLength: 8,
            maxLength: 30,
            regexp: '/^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,30}$/'
          }
        }
      }
    }),
    NgbModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  exports: []

})
export class AppModule {}
