import { Component } from '@angular/core';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {

	title = 'lavender-care-frontend-admin';

	constructor(){
		console.log('in app.js file');
		
	}
	

}
