import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ScreeningLinkComponent } from './pages/screening-link/screening-link.component';

const routes: Routes = [
	{
		path: 'screening-link',
		component: ScreeningLinkComponent,
	},
	{
		path: '',
		loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
