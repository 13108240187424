import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(public toastr: ToastrManager, private router: Router) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = JSON.parse(localStorage.getItem('auth_app_token') || '{}');
		req = req.clone({
			withCredentials: true,
		});
		if (Object.keys(token).length !== 0) {
			req = req.clone({
				headers: req.headers.set('Authorization', 'Bearer ' + token.value),
			});
			return next.handle(req).pipe(
				tap(
					(event : HttpResponse<any>) => {
						if(event && event.status && event.status == 200){
							const res = event.body;
							if(res.data && res.data.authorization != undefined && !res.data.authorization){
								this.router.navigate(['/pages/dashboard']);
							}
						}
					},
					(error: HttpErrorResponse) => {
						if (error.status == 410) {
							if (localStorage.getItem('auth_app_token') != null) {
								this.toastr.errorToastr('Your session has expired, please login again');
							}
							localStorage.removeItem('auth_app_token');
							this.router.navigate(['/auth/login']);
						}
					}
				)
			);
		} else {
			return next.handle(req);
		}
	}
}
