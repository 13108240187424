import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-card-header-elements',
	templateUrl: './card-header-elements.component.html',
	styleUrls: ['./card-header-elements.component.scss'],
})
export class CardHeaderElementsComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
